<template>
	<div class="home">
		<div class="nav-wrapper">
			<nav class="nav" v-if="!isMobile">
				<div
					class="nav-item"
					v-for="item in navList"
					:key="item.id"
					@click="navItemClick(item.id)">
					{{ item.name }}
				</div>
			</nav>
			<nav class="nav-mobile" v-else>
				<img class="logo" src="../assets/logo-color.png" alt="">
				
				<el-dropdown popper-class="right-menu" trigger="click">
					<img class="menu" src="../assets/menu.png" alt="">
					<template #dropdown>
						<el-dropdown-menu>
							<el-dropdown-item class="menu-item" v-for="item in navList" :key="item.id" @click="navItemClick(item.id)">
								{{ item.name }}
							</el-dropdown-item>
						</el-dropdown-menu>
					</template>
				</el-dropdown>
			</nav>
		</div>
		<div class="banner">
			
			<!-- <video src="../assets/banner.mp4" class="banner-video" autoplay loop muted></video> -->
			<!-- <div class="banner-content">
				<div class="text-normal">Pushing</div>
				<div class="text-main">The OP & NAVI</div>
				<div class="text-normal">Future</div>
				<div class="text-small">
					Welcome to the Next Generation of Decentralized AI
				</div>
			</div> -->
		</div>
		<div class="about" id="nav-item-1">
			<div class="section top">
				<div class="title">About</div>
				<div class="content" style="text-align: center">
					Navigation is a decentralized AI computing power platform
					based on the Optimism network, which aims to provide
					scalable and secure computing solutions for artificial
					intelligence AI, and uses blockchain technology to ensure
					the security and transparency of computing results.
				</div>
			</div>
			<div class="section bottom">
				<div class="section-l">
					<div class="title">Navigation network</div>
					<div class="content">
						Navigation combines robot learning (ML) and artificial
						intelligence (AI) to build the future digital economy by
						deploying robot learning, advanced encryption and
						self-made agents to the blockchain.
					</div>
				</div>
				<img src="../assets/okx_onboarding_welcome.png" class="logo" />
			</div>
		</div>
		<div class="routemap" id="nav-item-4">
			<div class="cards">
				<div class="card-item" >
					<img class="logo" src="../assets/developer.png" alt="" />
					<div class="title">Developer</div>
					<div class="content">
						The decentralized AI computing platform of Navigatio
						network can realize the blockchain with lightning-fast
						settlement speed. Ultra-low cost and unparalleled
						capital efficiency, quickly create decentralized
						applications that can be expanded on a large scale.
					</div>
				</div>
				<div class="card-item purple">
					<img class="logo" src="../assets/usersAI.png" alt="" />
					<div class="title">Users and institutions</div>
					<div class="content">
						Navigation and the world's leading organizations jointly
						explore the new dawn of finance. Navigation provides the
						most advanced infrastructure, which is optimized to
						support the construction of various institutional
						financial applications.
					</div>
				</div>
				<div class="card-item blue">
					<img class="logo" src="../assets/ecology.png" alt="" />
					<div class="title">Ecology</div>
					<div class="content">
						Navigation has established the largest and
						fastest-growing AI computing ecosystem. A global network
						of decentralized application explorers, verifiers,
						developers and partners will jointly create a truly free
						financial system.
					</div>
				</div>
			</div>

			<div class="routemap-main" id="nav-item-3">
				<div class="wrapper">
					<div class="title">Route map</div>
					<div class="content">
						In navigation, we are carrying out many interrelated
						projects, all of which have a common goal: to realize a
						beneficial Navigation network. We are actively building
						the core technologies of OpenCog Hyperon, AI-DSL and
						Navigation AI platforms. At the same time, we are
						promoting the use of Navigation platform through
						artificial intelligence services of many derivative
						projects in strategically selected vertical markets.
					</div>
				</div>
				<img src="../assets/routemap-bg-l.png" alt="" class="bg-l" />
				<img src="../assets/routemap-bg-r.png" alt="" class="bg-r" />
			</div>
		</div>

		<div class="temp-and-consultant">
			<img src="../assets/bg.png" alt="" class="tac-bg" />
			<div class="tac-information">
				<div class="title">Team and consultant</div>
				<div class="content">
					The Navigation team includes experienced engineers,
					scientists, researchers, entrepreneurs and marketers. Our
					core platform and artificial intelligence team are further
					supplemented by professional teams dedicated to financial,
					robotics, and other application fields.
				</div>
			</div>

			<div class="tac-scroll-wrapper">
				<div class="tac-scroll-main">
					<div class="tac-scroll-item">
						<div class="tac-logo-radius">
							<img
								src="../assets/expand.png"
								alt=""
								class="tac-logo" />
						</div>

						<div class="tac-content">
							<div class="title">Expand</div>
							<div class="content">
								Navigation uses a large number of distributed
								computers to perform tasks to create an extended
								computing network, which can handle a large
								number of task calculations.
							</div>
						</div>
					</div>
					<div class="tac-scroll-item">
						<div class="tac-logo-radius">
							<img
								src="../assets/security.png"
								alt=""
								class="tac-logo" />
						</div>
						<div class="tac-content">
							<div class="title">Security</div>
							<div class="content">
								Navigation uses blockchain technology to help improve the security of artificial intelligence applications and ensure the security and transparency of calculation results.
							</div>
						</div>
					</div>
					<div class="tac-scroll-item">
						<div class="tac-logo-radius">
							<img
								src="../assets/decentralization.png"
								alt=""
								class="tac-logo" />
						</div>
						<div class="tac-content">
							<div class="title">Decentralization</div>
							<div class="content">
								Navigation is a decentralized computing protocol, which means that it is not controlled by any individual or organization, which makes the protocol difficult to control and manipulate.
							</div>
						</div>
					</div>
					<div class="tac-scroll-item">
						<div class="tac-logo-radius">
							<img
								src="../assets/efficiency.png"
								alt=""
								class="tac-logo" />
						</div>
						<div class="tac-content">
							<div class="title">Efficiency</div>
							<div class="content">
								Navigation allows developers to decompose large tasks into multiple computers for simultaneous execution, which can shorten the training time and reduce the calculation cost.
							</div>
						</div>
					</div>
					<div class="tac-scroll-item">
						<div class="tac-logo-radius">
							<img
								src="../assets/usability.png"
								alt=""
								class="tac-logo" />
						</div>
						<div class="tac-content">
							<div class="title">Usability</div>
							<div class="content">
								Navigation allows developers to distribute computing tasks in distributed computer networks, which can make artificial intelligence programs less affected by server or network problems.
							</div>
						</div>
					</div>
				</div>
			</div>

      <div class="teamate" id="nav-item-2">
        <div class="item">
          <div class="infos">
            <div class="title">Founder</div>
            <div class="content">Navigation was founded by Jesse Holmes, and its mission is to create a decentralized, democratic, inclusive and beneficial general AI artificial intelligence. A "universal artificial intelligence" that does not depend on any central entity, is open to anyone and is not limited to the narrow goals of a single company or even a single country.</div>
          </div>
          <div class="usercard">
						<div class="avatar-wrapper"><img class="avatar" src="../assets/jesse.png" alt=""></div>
						<div class="name">Jesse Holmes</div>
          </div>
        </div>
				<div class="item">
					<div class="usercard" style="margin-bottom:50px;">
						<div class="avatar-wrapper"><img class="avatar" src="../assets/mike.png" alt=""></div>
						<div class="name">Mike Sandwick</div>
          </div>
          <div class="infos">
            <div class="title right">Co-founder</div>
            <div class="content right">Mike Sandwick's life work is to completely change the creation and delivery. He has been working on smart contracts for the past four years, and has built popular applications and libraries in this field (Proof of Existence, Streamium, Bitcore and Decentraland). He is currently the co-founder and chief technology officer of Navigation.</div>
          </div>
        </div>
      </div>
		</div>

		<div class="consultants">
			<div class="title">Consultants</div>
			<div class="list">
				<div class="usercard" style="margin-bottom:50px;">
					<div class="avatar-wrapper"><img class="avatar" src="../assets/kabir.png" alt=""></div>
					<div class="name">Kabir Gill</div>
				</div>
				<div class="usercard" style="margin-bottom:50px;">
					<div class="avatar-wrapper"><img class="avatar" src="../assets/james.png" alt=""></div>
					<div class="name">James Kissell</div>
				</div>
				<div class="usercard" style="margin-bottom:50px;">
					<div class="avatar-wrapper"><img class="avatar" src="../assets/jennifer.png" alt=""></div>
					<div class="name">Jennifer Li</div>
				</div>
				<div class="usercard" style="margin-bottom:50px;">
					<div class="avatar-wrapper"><img class="avatar" src="../assets/dan.png" alt=""></div>
					<div class="name">Dan Stein</div>
				</div>
			</div>
		</div>

		<div class="consultants">
			<div class="title">Community outreach</div>
			<div class="list">
				<div class="usercard" style="margin-bottom:50px;">
					<div class="avatar-wrapper"><img class="avatar" src="../assets/connie.png" alt=""></div>
					<div class="name">Connie Chan</div>
				</div>
				<div class="usercard" style="margin-bottom:50px;">
					<div class="avatar-wrapper"><img class="avatar" style="width:250px;height:250px;" src="../assets/Kazmi.jpg" alt=""></div>
					<div class="name">Kazmi</div>
				</div>
				<div class="usercard" style="margin-bottom:50px;">
					<div class="avatar-wrapper"><img class="avatar" src="../assets/jeff.png" alt=""></div>
					<div class="name">Jeff Stump</div>
				</div>
				<div class="usercard" style="margin-bottom:50px;">
					<div class="avatar-wrapper"><img class="avatar" src="../assets/eric.png" alt=""></div>
					<div class="name">Eric Wu</div>
				</div>
				<div class="usercard" style="margin-bottom:50px;">
					<div class="avatar-wrapper"><img class="avatar" src="../assets/kimberly.png" alt=""></div>
					<div class="name">Kimberly Tan</div>
				</div>
			</div>
		</div>

		<footer class="footer">
			<div class="wrapper">
				<img class="logo" src="../assets/logo.png" alt="">
				<div class="desc">Home of Benevolent OP & NAVI</div>
				<div class="link">
					<span>Community</span>
					<img class="telegram" src="../assets/twitter.png" alt="" @click="handleNavigate('https://twitter.com/NavigationFND')">
					<img class="telegram" src="../assets/Telegram.png" alt="" @click="handleNavigate('https://t.me/NavigationFND')">
					<img class="telegram" src="../assets/discord.png" alt="" @click="handleNavigate('https://discord.gg/rYFQy28Bd')">
					<img class="telegram" src="../assets/gitbook.png" alt="" @click="handleNavigate('https://navigation-litepaper.gitbook.io/navigation-white-paper/')">
					<img class="telegram" src="../assets/reddit.png" alt="">
					<img class="telegram" src="../assets/Youtube-fill.png" alt="">
					<!-- <img src="../assets/line.png" alt=""> -->
				</div>

				<div class="bottom">
					<span>2023 NavigationNET</span>
					<span>Cookie Policy and Privacy Policy.</span>
				</div>
			</div>
		</footer>
		<!-- <div class="about" id="nav-item-1"></div>
    <div class="" id="nav-item-2"></div>
    <div class="" id="nav-item-3"></div> -->
	</div>
</template>

<style lang="scss" scoped>
	::v-deep .el-dropdown-menu__item{
		justify-content: center;
	}

	.home {
		// scroll-snap-type: y mandatory;
		// height: 100vh;
		// overflow-y: scroll;
		overflow-x: hidden;
		color: #fff;
		user-select: none;
		background: #000;
		// .section{
		//   height: 100vh;
		//   background:#000;
		// scroll-snap-align: start;
		// }

		.nav-wrapper {
			// position: absolute;
			// top: 0;
			// left: 0;
			width: 100%;
			background: rgba(0, 0, 0, 0.7);
			z-index: 9;
			overflow-x: scroll;
			&::-webkit-scrollbar {
					display: none;
				}
			// @media screen and (max-width:600px){
			// 	overflow-x: scroll;
			// 	&::-webkit-scrollbar {
			// 		display: none;
			// 	}
			// }
		}
		.nav {
			display: flex;
			gap: 20px;
			width: 70%;
			margin: 0 auto;
			justify-content: space-between;
			height: 75px;
			line-height: 75px;
			
			.nav-item {
				color: #fff;
				min-width: 170px;
				text-align: center;
			}
		}
		.nav-mobile{
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0 20px;
			height: 75px;
			line-height: 75px;
			.logo{
				width: 100px;
			}
			.menu{
				width: 20px;
				height: 20px;
			}
		}
		.banner {
			position: relative;
			background: url("../assets/banner.png") no-repeat center center;
			background-size: cover;
			height: 80vh;
			// min-height: 800px;
			@media screen and (max-width:600px){
				width: 100vw;
				height: 250px;
			}
			.banner-video{
				height: 100%;
				width: 100%;
				object-fit: cover;
			}
			.banner-content {
				height: calc(90vh - 180px);
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				gap: 20px;

				.text-normal {
					font-size: 52px;
				}
				.text-main {
					font-size: 52px;
					font-weight: 700;
					color: #018ff1;
				}
				.text-small {
					font-size: 20px;
				}
			}
		}
		

		.about {
			background: #000;
			padding: 50px 0 150px;

			.section {
				&.top {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					width: 50%;
					margin: 0 auto 30px;
					height: 300px;
					@media screen and (max-width:600px){
						width: 100%;
						height: auto;
						padding: 15px 0;
						box-sizing: border-box;
					}
				}
				&.bottom {
					display: flex;
					justify-content: space-between;
					align-items: center;
					width: 70%;
					margin: 0 auto;
					@media screen and (max-width: 1024px) {
						flex-wrap: wrap;
						justify-content: center;
					}
					@media screen and (max-width:600px){
						width: 100%;
						height: auto;
						padding: 15px 0;
						box-sizing: border-box;
					}
					.section-l {
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						gap: 30px;
						width: 60%;
						@media screen and (max-width:600px){
							width: 100%;
							padding: 15px 0;
							box-sizing: border-box;
						}
					}
				}
				.title {
					font-size: 72px;
					font-weight: 700;
					color: #018ff1;
					@media screen and (max-width:600px){
						font-size: 24px;
						text-align: center;
					}
				}
				.content {
					font-size: 20px;
					line-height: 30px;
					@media screen and (max-width:600px){
						padding: 15px;
						box-sizing: border-box;
						font-size: 14px;
					}
				}

				.logo {
					width: 427px;
					height: 510px;
					@media screen and (max-width:600px){
						width: 227px;
						height: 255px;
					}
				}
			}
		}

		.routemap {
			background: #000;
			padding: 150px 0 100px;
			@media screen and (max-width:600px){
				padding: 50px 0;
			}
			.cards {
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				width: 75vw;
				margin: 0 auto;
				gap: 20px;
				@media screen and (max-width:600px){
					width: 90vw;
				}
				.card-item {
					margin-bottom: 100px;
					width: 30%;
					min-width: 250px;
					min-height: 611px;
					background: url("../assets/bg-yellow.png") no-repeat;
					background-size: 100% 100%;
					display: flex;
					flex-direction: column;
					align-items: center;
					padding-bottom: 30px;

					@media screen and (max-width: 1100px) {
						width: 45%;
					}
					@media screen and (max-width: 700px) {
						width: 100%;
					}
					@media screen and (max-width:600px){
						min-height: 500px;
					}
					&:hover {
						transform: scale(1.1);
						transition: all 0.3s;
					}
					&.purple {
						background: url("../assets/bg-purple.png") no-repeat;
						background-size: 100% 100%;
					}
					&.blue {
						background: url("../assets/bg-blue.png") no-repeat;
						background-size: 100% 100%;
					}
					.logo {
						width: 157px;
						height: 176px;
						margin-top: -75px;
					}

					.title {
						font-size: 36px;
						padding: 30px;
						margin-bottom: 30px;
						text-align: center;
						position: relative;
						width: 100%;
						box-sizing: border-box;
						@media screen and (max-width:600px){
							font-size: 24px;
						}
						&::before {
							content: "";
							height: 1px;
							width: 70%;
							background: #018ff1;
							position: absolute;
							bottom: 0;
							left: 50%;
							transform: translateX(-50%);
						}
					}
					.content {
						flex: 1;
						padding: 0 50px;
						line-height: 30px;
						text-align: center;
						word-break: keep-all;
						@media screen and (max-width:600px){
							padding: 0 15px;
						}
					}
				}
			}

			.routemap-main {
				display: flex;
				align-items: center;
				justify-content: center;
				position: relative;
				min-height: 423px;
				@media screen and (max-width:600px){
					min-height: 210px;
				}
				.wrapper {
					width: 50%;
					text-align: center;
					@media screen and (max-width:600px){
						width: 100%;
						padding: 15px 0;
						box-sizing: border-box;
					}
					.title {
						font-size: 72px;
						font-weight: 700;
						color: #018ff1;
						margin-bottom: 30px;
						@media screen and (max-width:600px){
							font-size: 24px;
						}
					}
					.content {
						font-size: 20px;
						line-height: 30px;
						@media screen and (max-width:600px){
							font-size: 14px;
							padding: 0 50px;
						}
					}
				}
				.bg-l,
				.bg-r {
					position: absolute;
					width: 328px;
					height: 423px;
					top: 50%;
					transform: translateY(-50%);
					@media screen and (max-width:600px){
						width: 164px;
						height: 210px;
					}
				}
				.bg-l {
					left: 0;
				}
				.bg-r {
					right: 0;
				}
			}
		}

		.temp-and-consultant {
			background: #000;
			.tac-bg {
				width: 100%;
				margin: 100px 0;
				@media screen and (max-width:600px){
						margin:30px 0;
				}
			}

			.tac-information {
				width: 50vw;
        text-align: center;
				margin: 0 auto 100px;
				@media screen and (max-width:600px){
						width: 100%;
						height: auto;
						padding: 15px;
						box-sizing: border-box;
						margin: 0 auto 30px;
				}
				.title {
					font-size: 72px;
					font-weight: 700;
					color: #018ff1;
					margin-bottom: 30px;
					@media screen and (max-width:600px){
						font-size: 24px;
					}
				}
				.content {
					font-size: 20px;
					line-height: 30px;
					@media screen and (max-width:600px){
						font-size: 14px;
					}
				}
			}

			.tac-scroll-wrapper {
				padding: 50px 30px 0px;
				overflow-x: scroll;
        position: relative;
        height: 330px;
				
				&::-webkit-scrollbar {
					display: none;
				}
        &:hover {
          &::-webkit-scrollbar {
            display: block;
          }
        }
        &::-webkit-scrollbar {width: 2px;height: 5px;}
        &::-webkit-scrollbar-thumb {border-radius: 10px;box-shadow: inset 0 0 5px rgba(0,0,0,0.2);background: #777;}
        &::-webkit-scrollbar-track {box-shadow: inset 0 0 5px rgba(0,0,0,0.2);border-radius: 0;background: transparent;}
        .tac-scroll-main{
          width: calc(390px * 5 + 20px * 4);
          display: flex;
          gap: 20px;
					
					@media screen and (max-width:600px){
						width: calc(290px * 5 + 20px * 4);
					}
        }
				.tac-scroll-item {
					border-radius: 20px;
					background: linear-gradient(
						135deg,
						#0f2330 0%,
						#030d14 100%
					);
					width: 390px;
					height: 314px;
					display: flex;
					flex-direction: column;
					align-items: center;
					@media screen and (max-width:600px){
						width: 290px;
						// height: 214px;
					}
					.tac-logo-radius {
						border-radius: 50%;
						padding: 15px;
						background: #000;
						margin-top: -50px;
						display: flex;
						align-items: center;
						justify-content: center;
						border: 1px solid #777;
					}
					.tac-logo {
						width: 101px;
						height: 105px;
					}
					.tac-content {
						flex: 1;
						padding: 30px;
						@media screen and (max-width:600px){
							padding: 15px;
						}
						.title {
							font-size: 24px;
							text-align: center;
							margin-bottom: 20px;
						}
						.content {
							text-align: center;
							word-break: keep-all;
							line-height: 20px;
						}
					}
				}
			}

      .teamate{
        width: 80%;
        border-top: 1px solid #777;
        padding: 30px 0;
        margin: 30px auto 0;
				@media screen and (max-width:600px){
						width: 100%;
						height: auto;
						padding: 15px;
						box-sizing: border-box;
				}
        .item{
					display: flex;
					// align-items: center;
					justify-content: space-between;
					padding: 100px 0;
					@media screen and (max-width:1024px) {
						flex-direction: column;
						flex-wrap: wrap;
						align-items: center;
					}
					@media screen and (max-width:600px){
						padding: 30px 0;
					}
          .infos{
						width: 50%;
						@media screen and (max-width:1024px) {
							width: 100%;
						}
            .title{
              font-size: 48px;
              font-weight: 700;
              color: #018ff1;
              margin-bottom: 30px;
							&.right{
								text-align: right;
								@media screen and (max-width:600px){
									font-size: 24px;
									text-align: center !important;
								}
							}
							@media screen and (max-width:600px){
									font-size: 24px;
									text-align: center !important;
							}
            }
            .content {
              font-size: 20px;
              line-height: 30px;
							&.right{
								text-align: right;
								@media screen and (max-width:600px){
									text-align: center;
								}
							}
							@media screen and (max-width:600px){
									font-size: 14px;
							}
            }
          }
					.usercard{
						margin: 50px auto 0;
						@media screen and (max-width:1024px) {
							margin: 150px auto 0;
						}
					}
        }
      }
		}
		.consultants{
				width: 70%;
				margin: 0 auto;
				@media screen and (max-width:600px){
						width: 100%;
						padding: 15px;
						box-sizing: border-box;
				}
				.title{
					font-size: 48px;
					font-weight: 700;
					color: #018ff1;
					margin-bottom: 30px;
					@media screen and (max-width:600px){
						font-size: 24px;
						text-align: center;
					}
				}
				.list{
					display: flex;
					margin-top: 150px;
					gap: 10px;
					@media screen and (max-width:1024px) {
						flex-direction: column;
						align-items: center;
						gap:100px;
					}
					
				}
			}

		.footer{
			background: #121212;
			padding: 30px 0 0;

			.wrapper{
				width: 70%;
				margin: 0 auto;
				@media screen and (max-width:600px){
						width: 100%;
						height: auto;
						padding: 15px;
						box-sizing: border-box;
						font-size: 12px;
				}
			}
			.logo{
				width: 315px;
				height: 88px;
				@media screen and (max-width:600px){
						width: 157px;
						height: 44px;
				}
			}
			.desc{
				margin: 30px 0 50px;
			}
			.link{
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				// gap: 20px;
				margin-block: 30px;

				.telegram{
					width: 22px;
					height: 22px;
					&:not(:last-child){
						margin-right: 20px;
					}
				}
				@media screen and (max-width:600px){
						gap: 10px;
				}
			}
			.bottom{
				border-top: 1px solid #777;
				padding: 30px 0;
				display: flex;
				justify-content: space-between;
			}
		}
		.usercard{
			width: 345px;
			height: 289.5px;
			border-radius: 20px;
			background: #0A0A0A;
			border: 1px solid #3B3B3B;
			display: flex;
			flex-direction: column;
			align-items: center;
			
			.avatar-wrapper{
				display: flex;
				justify-content: center;
				align-items: center;
				background: #000000;
				width: 211px;
				height: 211px;
				padding: 10px;
				border-radius: 50%;
				margin-top: -100px;
				overflow: hidden;
				
				.avatar{
					width: 211px;
					height: 211px;
				}
			}

			.name{
				margin-top: 50px;
				font-size: 36px;
				
			}
		}
	}
</style>

<script setup>
	import { onMounted, ref } from "vue";
	const navList = ref([
		{
			id: 1,
			name: "About",
		},
		{
			id: 2,
			name: "Team and consultant",
		},
		{
			id: 3,
			name: "Route map",
		},
		{
			id: 4,
			name: "Ecology",
		},
		{
			id: 5,
			name: "Developer",
		},
		{
			id: 6,
			name: "Users and institutions",
		},
	]);
	const isMobile = ref(false);

	const navItemClick = (index) => {
		if(index>3){
			index = 4;
		}
		document.querySelector("#nav-item-" + index).scrollIntoView({
			behavior: "smooth",
		});
	};

	const handleNavigate = (url) => {
		window.location.href = url;
	}

	onMounted(()=>{
		let clientWidth = document.body.clientWidth;
		if (clientWidth < 600) {
			isMobile.value = true;
		} else {
			isMobile.value = false;
		}
	})
</script>
